import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout.vue";
import AuthLayout from "@/pages/Dashboard/Pages/AuthLayout.vue";

// Dashboard pages
import Dashboard from "@/pages/Dashboard/Dashboard.vue";

// Pages
import User from "@/pages/Dashboard/Pages/UserProfile.vue";
import Login from "@/pages/Dashboard/Pages/Login.vue";

// Calendar
import Calendar from "@/pages/Dashboard/Calendar.vue";
// Charts
import Charts from "@/pages/Dashboard/Charts.vue";
import Widgets from "@/pages/Dashboard/Widgets.vue";

import TiposMedicamento from '@/pages/takeda/TiposMedicamento.vue'
import TipoMedicamento from '@/pages/takeda/TipoMedicamento.vue'
import Medicamentos from '@/pages/takeda/Medicamentos.vue'
import Medicamento from '@/pages/takeda/Medicamento.vue'
import DicasSaude from '@/pages/takeda/DicasSaude.vue'
import TiposDicasSaude from '@/pages/takeda/TiposDicasSaude.vue'
import TipoDicasSaude from '@/pages/takeda/TipoDicasSaude.vue'
import DicaSaude from '@/pages/takeda/DicaSaude.vue'
import FAQs from '@/pages/takeda/FAQs.vue'
import FAQ from '@/pages/takeda/FAQ.vue'
import Configuracoes from '@/pages/takeda/Configuracoes.vue'

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  redirect: 'Login',
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
  ],
};

const routes = [ 
  authPages,
  {
    path: "/admin",
    component: DashboardLayout,
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        components: { default: Dashboard },
      },
      {
        path: "tipos-medicamento",
        name: "Tipos Medicamento",
        component: TiposMedicamento,
      },
      {
        path: "faqs",
        name: "FAQs",
        component: FAQs,
      },
      {
        path: "faq/:id",
        name: "FAQ",
        component: FAQ,
      },
      {
        path: "tipos-dicas-saude",
        name: "Tipos Dicas de Saúde",
        component: TiposDicasSaude,
      },
      {
        path: "tipo-dicas-saude/:id",
        name: "Tipo Dicas Saúde",
        component: TipoDicasSaude,
      },
      {
        path: "dicas-saude",
        name: "Dicas de Saúde",
        component: DicasSaude,
      },
      {
        path: "dica-saude/:id",
        name: "Dica de Saúde",
        component: DicaSaude,
      },
      {
        path: "tipo-medicamento/:id",
        name: "Tipo Medicamento",
        component: TipoMedicamento,
      },
      {
        path: "medicamentos",
        name: "Medicamentos",
        component: Medicamentos,
      },
      {
        path: "configuracoes",
        name: "Configurações",
        component: Configuracoes,
      },
      {
        path: "medicamento/:id",
        name: "Medicamento",
        component: Medicamento,
      },
      {
        path: "calendar",
        name: "Calendar",
        components: { default: Calendar },
      },
      {
        path: "charts",
        name: "Charts",
        components: { default: Charts },
      },
      {
        path: "widgets",
        name: "Widgets",
        components: { default: Widgets },
      },
      {
        path: "user",
        name: "User Page",
        components: { default: User },
      },
    ],
  },
];

export default routes;
