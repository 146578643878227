import DropDown from "./components/Dropdown.vue";
import { ValidationProvider } from "vee-validate";
import { ValidationObserver } from "vee-validate";
import VueKnob from 'vue-knob'
import DoughnutChart from 'vue-doughnut-chart'
/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(Vue) {
    Vue.component("drop-down", DropDown);
    Vue.component("ValidationProvider", ValidationProvider);
    Vue.component("ValidationObserver", ValidationObserver);
    Vue.component('vue-knob', VueKnob);
    Vue.component('DoughnutChart', DoughnutChart);
  },
};

export default GlobalComponents;
