<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-text md-card-header-green">
          <div class="card-text">
            <h4 class="title">{{ id == 'new' ? 'INCLUIR' : 'EDITAR' }}</h4>
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout">
            <label class="md-layout-item md-size-15 md-form-label">
              Nome
            </label>
            <div class="md-layout-item">
              <md-field>
                <md-input v-model="tipoDicaSaude.nome" type="text" aria-autocomplete="none" auto-complete="off">
                </md-input>
              </md-field>
            </div>
          </div>

          <div class="md-layout">
            <label class="md-layout-item md-size-15 md-form-label">
              Observações
            </label>
            <div class="md-layout-item">
              <md-field>
                <md-input v-model="tipoDicaSaude.observacoes" type="text"></md-input>
                <span class="md-helper-text">Observações de aplicação etc...</span>
              </md-field>
            </div>
          </div>
          <div class="md-layout">
            <label class="md-layout-item md-size-15 md-form-label">
              Medicamento
            </label>
            <div class="md-layout-item">
              <md-field>
                <md-select v-model="medicamentoId" id="abc" name="abc" @md-selected="onMedicamentoChange($event)">
                  <md-option :value="0">SELECIONAR</md-option>
                  <md-option v-for="medicamento of medicamentos" :key="medicamento.id" :value="medicamento.id">
                    {{ medicamento.nome | uppercase }}
                  </md-option>
                </md-select>
              </md-field>
            </div>
          </div>


        </md-card-content>
        <md-card-actions md-alignment="right">
          <md-button class="md-default" @click="back()">CANCELAR</md-button>
          <md-button class="md-success" @click="save()">SALVAR</md-button>
        </md-card-actions>
      </md-card>
    </div>

  </div>
</template>
<script>
import api from './../../services/api';

export default {
  components: {},
  data() {
    return {
      id: null,
      medicamentoId: 0,
      tipoDicaSaude: {},
      medicamentos: []
    };
  },
  created() {
    this.id = this.$route.params.id;
    if (this.id != 'new') {
      this.getData();
    }

    this.getMedicamentos();
  },
  methods: {
    async save() {
      const { nome, observacoes, medicamento } = this.tipoDicaSaude;
      if (!nome) {
        this.notify('Nome não informado');
        return;
      }

      try {
        this.id == 'new' ?
          await api.post('/tipo-dicas-saude', { nome, observacoes, medicamento }) :
          await api.patch(`/tipo-dicas-saude/${this.id}`, { nome, observacoes, medicamento });

        this.back();
      } catch (e) {
        const { data } = e;
        const { message } = data;
        this.notify(message ?? data.toString());
      }
    },
    back() {
      this.$router.push({ path: '/admin/tipos-dicas-saude', replace: true })
    },
    getData() {
      api.get(`/tipo-dicas-saude/${this.id}`)
        .then(value => {
          const { data } = value;
          this.tipoDicaSaude = data;
          this.medicamentoId = this.tipoDicaSaude.medicamento.id;
          this.ontipoDicaSaudeChange(this.medicamentoId);
        })
        .catch(error => console.log(error));
    },
    getMedicamentos() {
      api.get('/medicamentos')
        .then(value => {
          const { data } = value;
          this.medicamentos = data;
        })
        .catch(error => console.log(error));
    },
    onMedicamentoChange(event) {
      this.tipoDicaSaude.medicamento = this.medicamentos.find(t => t.id == event);
    },
    notify(message) {
      this.$notify({
        timeout: 2500,
        message,
        icon: "add_alert",
        horizontalAlign: 'right',
        verticalAlign: 'top',
        type: 'warning',
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.md-inline-checkboxes {
  display: inline-flex;

  .md-checkbox {
    margin-top: 15px !important;
  }
}

.md-checkbox,
.md-radio {
  margin-top: 15px;
  margin-bottom: 0.5rem;
}

.md-checkbox,
.md-radio {
  display: flex;
}

.md-radio .md-radio-container {
  margin-left: 5px;
  position: relative;
  left: -3px;
}

.md-form-label+.md-layout-item .md-checkbox:not(:first-child),
.md-form-label+.md-layout-item+.md-layout-item .md-radio:not(:first-child),
.md-form-label+.md-layout-item .md-radio {
  margin-top: 0;
}

.form-control-static {
  margin-top: 6px;
}
</style>
