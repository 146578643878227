<template>
  <router-view></router-view>
</template>

<script>
export default {};
</script>
<style lang="scss">
.ct-chart.is-positivo {

  .ct-series-a .ct-point,
  .ct-series-a .ct-line,
  .ct-series-a .ct-bar,
  .ct-series-a .ct-slice-donut {
    stroke: #467fc1 !important;
    stroke-width: 16px !important;
  }
}

.ct-chart.is-negativo {

  .ct-series-a .ct-point,
  .ct-series-a .ct-line,
  .ct-series-a .ct-bar,
  .ct-series-a .ct-slice-donut {
    stroke: #e82227 !important;
    stroke-width: 16px !important;
  }
}

.ct-chart.is-medicamentos {

  // .ct-label.ct-label.ct-horizontal.ct-end {
  //   position: relative;
  //   justify-content: flex-end;
  //   text-align: right;
  //   transform-origin: 100% 0;
  //   transform: translate(-50%) rotate(-45deg);
  //   white-space: nowrap;
  // }
  svg.ct-chart-bar,
  svg.ct-chart-line {
    overflow: visible;
  }

  .ct-label.ct-label.ct-horizontal.ct-end {
    position: relative;
    justify-content: flex-end;
    text-align: right;
    transform-origin: 100% 0;
    transform: translate(-50%) rotate(-45deg);
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}</style>
