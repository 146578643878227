<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-text md-card-header-green">
          <div class="card-text">
            <h4 class="title">{{ id == 'new' ? 'INCLUIR' : 'EDITAR' }}</h4>
          </div>
        </md-card-header>
        <md-card-content>
          <div class="md-layout">
            <label class="md-layout-item md-size-15 md-form-label">
              Nome
            </label>
            <div class="md-layout-item">
              <md-field>
                <md-input v-model="medicamento.nome" type="text" aria-autocomplete="none" auto-complete="off">
                </md-input>
                <span class="md-helper-text">Exemplo(comprimidos, ampolas, drágeas, cápsulas)</span>
              </md-field>
            </div>
          </div>
          <div class="md-layout">
            <label class="md-layout-item md-size-15 md-form-label">
              Concentração
            </label>
            <div class="md-layout-item">
              <md-field>
                <md-input v-model="medicamento.concentracao" type="text">
                </md-input>
              </md-field>
            </div>
          </div>
          <div class="md-layout">
            <label class="md-layout-item md-size-15 md-form-label">
              Tipo de Medicamento
            </label>
            <div class="md-layout-item">
              <md-field>
                <md-select v-model="tipoMedicamentoId" id="abc" name="abc" @md-selected="onTipoMedicamentoChange($event)">
                  <md-option :value="0">SELECIONAR</md-option>
                  <md-option v-for="tipoMedicamento of tiposMedicamento" :key="tipoMedicamento.id"
                    :value="tipoMedicamento.id">
                    {{ tipoMedicamento.nome | uppercase }}
                  </md-option>
                </md-select>
              </md-field>
            </div>
          </div>
          <div class="md-layout">
            <label class="md-layout-item md-size-15 md-form-label">
              Status
            </label>
            <div class="md-layout-item">
              <md-field>
                <md-select v-model="medicamento.ativo">
                  <md-option :value="1">ATIVO</md-option>
                  <md-option :value="0">INATIVO</md-option>
                </md-select>
              </md-field>
            </div>
          </div>
          <div class="md-layout">
            <label class="md-layout-item md-size-15 md-form-label">
              Controla Estoque
            </label>
            <div class="md-layout-item">
              <md-field>
                <md-select v-model="medicamento.controlaEstoque">
                  <md-option :value="true">SIM</md-option>
                  <md-option :value="false">NÃO</md-option>
                </md-select>
              </md-field>
            </div>
          </div>
          <div class="md-layout text-right float-right">
            <label class="md-layout-item md-size-15 md-form-label">
              Vacina
            </label>
            <div class="md-layout-item">
              <md-field>
                <md-select v-model="medicamento.vacina">
                  <md-option :value="true">SIM</md-option>
                  <md-option :value="false">NÃO</md-option>
                </md-select>
              </md-field>
            </div>
          </div>
          <div class="md-layout">
            <label class="md-layout-item md-size-15 md-form-label">
              Observações
            </label>
            <div class="md-layout-item">
              <md-field>
                <md-input v-model="medicamento.observacoes" type="text"></md-input>
                <span class="md-helper-text">Observações de aplicação etc...</span>
              </md-field>
            </div>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="right">
          <md-button class="md-default" @click="back()">CANCELAR</md-button>
          <md-button class="md-success" @click="save()">SALVAR</md-button>
        </md-card-actions>
      </md-card>
    </div>

  </div>
</template>
<script>
import api from './../../services/api';

export default {
  components: {},
  data() {
    return {
      id: null,
      tipoMedicamentoId: 0,
      medicamento: {
        tipoMedicamento: null,
        ativo: 1,
        controlaEstoque: true,
        nome: '',
        concentracao: '',
        observacoes: ''
      },
      tiposMedicamento: []
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.getTiposMedicamento();
  },
  mounted() {
    if (this.id != 'new') {
      this.getMedicamento();
    }
  },
  methods: {
    onTipoMedicamentoChange(event) {
      console.log(event);
      this.medicamento.tipoMedicamento = this.tiposMedicamento.find(t => t.id == event);
    },
    async save() {
      const { tipoMedicamento, ativo, controlaEstoque, nome, concentracao, observacoes, vacina } = this.medicamento;

      if (!tipoMedicamento) {
        this.notify('Tipo do medicamento não informado');
        return;
      }

      if (!nome) {
        this.notify('Nome não informado');
        return;
      }

      const payload = {
        tipoMedicamento,
        ativo,
        controlaEstoque,
        nome,
        concentracao,
        observacoes,
        vacina
      };

      try {
        this.id == 'new' ?
          await api.post('/medicamentos', payload) :
          await api.patch(`/medicamentos/${this.id}`, payload);

        this.back();
      } catch (e) {
        console.log(e);
        const { data } = e;
        const { message } = data;
        this.notify(message ?? data.toString(), 'danger');
      }
    },
    back() {
      this.$router.push({ path: '/admin/medicamentos', replace: true })
    },
    getTiposMedicamento() {
      api.get(`/tipo-medicamento`)
        .then(value => {
          const { data } = value;
          const items = [];
          for (const item of data) {
            const { id, nome } = item;
            items.push({
              id,
              nome
            });
          }
          this.tiposMedicamento = items;
        })
        .catch(error => console.log(error));
    },
    getMedicamento() {
      api.get(`/medicamentos/${this.id}`)
        .then(value => {
          const { data } = value;
          this.medicamento = data;
          this.tipoMedicamentoId = this.medicamento.tipoMedicamento.id;
          this.tipoMedicamento = this.medicamento.tipoMedicamento;
        })
        .catch(error => console.log(error));
    },
    notify(message, type = 'warning') {
      this.$notify({
        timeout: 2500,
        message,
        icon: "add_alert",
        horizontalAlign: 'right',
        verticalAlign: 'top',
        type: type,
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.md-inline-checkboxes {
  display: inline-flex;

  .md-checkbox {
    margin-top: 15px !important;
  }
}

.md-checkbox,
.md-radio {
  margin-top: 15px;
  margin-bottom: 0.5rem;
}

.md-checkbox,
.md-radio {
  display: flex;
}

.md-radio .md-radio-container {
  margin-left: 5px;
  position: relative;
  left: -3px;
}

.md-form-label+.md-layout-item .md-checkbox:not(:first-child),
.md-form-label+.md-layout-item+.md-layout-item .md-radio:not(:first-child),
.md-form-label+.md-layout-item .md-radio {
  margin-top: 0;
}

.form-control-static {
  margin-top: 6px;
}
</style>
