<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-text md-card-header-green">
          <div class="card-text">
            <h4 class="title">EDITAR</h4>
          </div>
        </md-card-header>
        <md-card-content>
          <div class="md-layout">
            <md-field>
              <label>Termos de Uso</label>
              <md-textarea v-model="configucacao.termosDeUso" class="md-autogrow" rows="20"></md-textarea>
            </md-field>
          </div>
          <div class="md-layout">
            <md-field>
              <label>Política de Privacidade</label>
              <md-textarea v-model="configucacao.politicaPrivacidade" class="md-autogrow" rows="20"></md-textarea>
            </md-field>
          </div>
          <div class="md-layout">
            <md-field>
              <label>Link do vídeo(Android)</label>
              <md-input v-model="configucacao.videoIntroducaoPath" type="text"></md-input>
            </md-field>
          </div>
          <div class="md-layout">
            <md-field>
              <label>Link do vídeo(iOS)</label>
              <md-input v-model="configucacao.videoIntroducaoPathIos" type="text"></md-input>
            </md-field>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="right">
          <md-button class="md-success" @click="save()">SALVAR</md-button>
        </md-card-actions>
      </md-card>
    </div>

  </div>
</template>
<script>
import api from './../../services/api';

export default {
  components: {},
  data() {
    return {
      configucacao: {
        id: null,
        termosDeUso: '',
        politicaPrivacidade: '',
        videoIntroducaoPath: '',
        videoIntroducaoPathIos: ''
      }
    };
  },
  created() {
    this.getConfiguracao();
  },
  methods: {
    async save() {
      const { termosDeUso = '', politicaPrivacidade = '', videoIntroducaoPath = '', videoIntroducaoPathIos = '' } = this.configucacao;

      if (!termosDeUso) {
        this.notify('Termos de uso não informado');
        return;
      }

      if (!politicaPrivacidade) {
        this.notify('Política de privacidade não informada');
        return;
      }

      if (!videoIntroducaoPath) {
        this.notify('Link do vídeo de introdução Android não informado');
        return;
      }

      if (!videoIntroducaoPathIos) {
        this.notify('Link do vídeo de introdução iOS não informado');
        return;
      }

      try {

        await api.post('/configuracoes', {
          ...this.configucacao
        });

        this.notify('Configurações salvas com sucesso!', 'success');
      } catch (e) {
        const { data } = e;
        const { message } = data;
        this.notify(message ?? data.toString());
      }
    },
    getConfiguracao() {
      api.get(`/configuracoes`)
        .then(value => {
          const { data } = value;
          this.configucacao = data;
        })
        .catch(error => console.log(error));
    },
    notify(message, type = 'warning') {
      this.$notify({
        timeout: 2500,
        message,
        icon: "add_alert",
        horizontalAlign: 'right',
        verticalAlign: 'top',
        type,
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.md-inline-checkboxes {
  display: inline-flex;

  .md-checkbox {
    margin-top: 15px !important;
  }
}

.md-checkbox,
.md-radio {
  margin-top: 15px;
  margin-bottom: 0.5rem;
}

.md-checkbox,
.md-radio {
  display: flex;
}

.md-radio .md-radio-container {
  margin-left: 5px;
  position: relative;
  left: -3px;
}

.md-form-label+.md-layout-item .md-checkbox:not(:first-child),
.md-form-label+.md-layout-item+.md-layout-item .md-radio:not(:first-child),
.md-form-label+.md-layout-item .md-radio {
  margin-top: 0;
}

.form-control-static {
  margin-top: 6px;
}
</style>
