import { render, staticRenderFns } from "./CardDashboardData.vue?vue&type=template&id=1b572020&scoped=true&"
import script from "./CardDashboardData.vue?vue&type=script&lang=js&"
export * from "./CardDashboardData.vue?vue&type=script&lang=js&"
import style0 from "./CardDashboardData.vue?vue&type=style&index=0&id=1b572020&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b572020",
  null
  
)

export default component.exports