import moment from "moment";

export const TIPOS_DASHBOARD = {
  usuarios_cadastrados: 0,
  usuarios_excluidos: 1,
  usuarios_admin: 2,
  usuarios_dependentes: 3,
  cuidadores_cadastrados: 4,
  cuidadores_alertas: 5,
  cuidadores_aceites: 6,
  cuidadores_nao_tomaram: 7,
  medicamentos_usuario: 8,
  usuarios_genero: 9,
  usuarios_idade: 10,
  usuarios_inicio_uso: 11,
  usuarios_tempo_uso: 12,
};

export const formatDate = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

export const getDashboardUrl = (tipoDashboard) => {
  let keyName = "";

  for (const key in TIPOS_DASHBOARD) {
    const value = TIPOS_DASHBOARD[key];

    if (value == tipoDashboard) {
      keyName = key;
      break;
    }
  }

  return `/dashboards/${keyName.split("_").join("-")}`;
};

export const getDashboardPeriodo = (periodo, mes) => {
  const now = moment();
  const month = now.get("month");
  let inicio = now;
  let fim = now;

  switch (periodo) {
    case 0: //Fiscal year Takeda (abr-mar)
      if (month < 2) {
        inicio = moment()
          .subtract(1, "years")
          .set({ month: 3 })
          .startOf("month");
      } else {
        inicio = moment().set({ month: 3 }).startOf("month");
      }
      break;
    case 1: //Mensal
      inicio = moment()
        .set({ month: mes.monthIndex - 1, year: mes.year })
          .startOf("month");
        
      fim = moment()
        .set({ month: mes.monthIndex - 1, year: mes.year })
        .endOf("month");
      break;
    case 2: //Semanal
      inicio = moment().startOf("week");
      break;
    case 3: //YTD (a partir de abr)
      if (month < 3) {
        inicio = moment()
          .subtract(1, "years")
          .set({ month: 3 })
          .startOf("month");
      } else {
        inicio = moment().set({ month: 3 }).startOf("month");
      }
      break;
    case 4: //MAT (ano calendário)
      inicio = moment().startOf("year");
    default:
      break;
  }

  return [formatDate(inicio), formatDate(fim)];
};
