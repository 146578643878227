<template>
  <div class="wrapper" :class="[
    { 'nav-open': $sidebar.showSidebar },
    { rtl: false },
  ]">
    <notifications></notifications>
    <side-bar :active-color="sidebarBackground" :background-image="sidebarBackgroundImage"
      :data-background-color="sidebarBackgroundColor" :user="user">
      <user-menu :user="user"></user-menu>
      <mobile-menu></mobile-menu>
      <template slot="links">
        <sidebar-item :link="{ name: 'Configurações', icon: 'settings', path: '/admin/configuracoes' }">
        </sidebar-item>
        <sidebar-item :link="{ name: 'Dashboard', icon: 'dashboard', path: '/admin/dashboard' }">
        </sidebar-item>
        <sidebar-item :link="{ name: 'Cadastros', icon: 'image' }">
          <sidebar-item :link="{ name: 'Tipos Medicamento', icon: 'box', path: '/admin/tipos-medicamento' }">
          </sidebar-item>
          <sidebar-item :link="{ name: 'Tipos Dicas Saúde', icon: 'box', path: '/admin/tipos-dicas-saude' }">
          </sidebar-item>
          <!-- <sidebar-item :link="{ name: 'Grupos FAQ', icon: 'box', path: '/admin/tipos-faq' }">
          </sidebar-item> -->
        </sidebar-item>
        <sidebar-item :link="{ name: 'Medicamentos', icon: 'medication', path: '/admin/medicamentos' }">
        </sidebar-item>
        <sidebar-item :link="{ name: 'FAQ', icon: 'list', path: '/admin/faqs' }">
        </sidebar-item>        
        <sidebar-item :link="{ name: 'Dicas de Saúde', icon: 'lightbulb', path: '/admin/dicas-saude' }">
        </sidebar-item>
        <!-- <sidebar-item :link="{ name: 'Components', icon: 'apps' }">
          <sidebar-item :link="{ name: 'Buttons', path: '/components/buttons' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Grid System', path: '/components/grid-system' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Panels', path: '/components/panels' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Sweet Alert', path: '/components/sweet-alert' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Notifications', path: '/components/notifications' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Icons', path: '/components/icons' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Typography', path: '/components/typography' }"></sidebar-item>
          <sidebar-item :link="{ name: 'MultiLevel', disableCollapse: true }">
            <sidebar-item :link="{ name: 'Third level menu', path: '#!' }" />
            <sidebar-item :link="{ name: 'Just another link', path: '#a' }" />
            <sidebar-item :link="{ name: 'One last link', path: '#b' }" />
          </sidebar-item>
        </sidebar-item> -->
        <!-- <sidebar-item :link="{ name: 'Forms', icon: 'content_paste' }">
          <sidebar-item :link="{ name: 'Regular Forms', path: '/forms/regular' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Extended Forms', path: '/forms/extended' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Validation Forms', path: '/forms/validation' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Wizard', path: '/forms/wizard' }"></sidebar-item>
        </sidebar-item> -->
        <!-- <sidebar-item :link="{ name: 'Tables', icon: 'grid_on' }">
          <sidebar-item :link="{ name: 'Regular Tables', path: '/table-list/regular' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Extended Tables', path: '/table-list/extended' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Paginated Tables', path: '/table-list/paginated' }"></sidebar-item>
        </sidebar-item> -->
        <!-- <sidebar-item :link="{ name: 'Widgets', icon: 'widgets', path: '/admin/widgets' }"></sidebar-item> -->
        <!-- <sidebar-item :link="{ name: 'Charts', icon: 'timeline', path: '/admin/charts' }"></sidebar-item> -->
        <!-- <sidebar-item :link="{ name: 'Calendar', icon: 'date_range', path: '/admin/calendar' }"></sidebar-item> -->
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <fixed-plugin :color.sync="sidebarBackground" :colorBg.sync="sidebarBackgroundColor"
        :sidebarMini.sync="sidebarMini" :sidebarImg.sync="sidebarImg" :image.sync="sidebarBackgroundImage">
      </fixed-plugin>

      <div :class="{ content: !$route.meta.hideContent }" @click="toggleSidebar">
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view :user="user"></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import routes from './../../../routes/routes'

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
    document.getElementsByClassName(className)[0].scrollTop = 0;
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

function reinitScrollbar() {
  let docClasses = document.body.classList;
  let isWindows = navigator.platform.startsWith("Win");
  if (isWindows) {
    // if we are on windows OS we activate the perfectScrollbar function
    initScrollbar("sidebar");
    initScrollbar("sidebar-wrapper");
    initScrollbar("main-panel");

    docClasses.add("perfect-scrollbar-on");
  } else {
    docClasses.add("perfect-scrollbar-off");
  }
}

import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import FixedPlugin from "../../FixedPlugin.vue";
import UserMenu from "./Extra/UserMenu.vue";
import { ZoomCenterTransition } from "vue2-transitions";
import api from './../../../services/api';

export default {
  components: {
    TopNavbar,
    ContentFooter,
    FixedPlugin,
    MobileMenu,
    UserMenu,
    ZoomCenterTransition,
  },
  data() {
    return {
      sidebarBackgroundColor: "black",
      sidebarBackground: "green",
      sidebarBackgroundImage: "./img/sidebar-2.jpg",
      sidebarMini: true,
      sidebarImg: true,
      accessToken: '',
      user: {},
      menu: [],
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    validateUser() {
      api.get(`/auth/validate/${this.accessToken}`)
        .then(value => {
          const { data } = value;
          this.user = data;
        })
        .catch(error => console.log(error));
    }
  },
  updated() {
    reinitScrollbar();
  },
  beforeMount() {
    const token = localStorage.getItem('access_token');
    if (!token) {
      console.log('Sair');
      this.$router.replace('/login');
      return;
    }
    this.accessToken = token;
  },
  mounted() {
    reinitScrollbar();
    this.validateUser();
  },
  watch: {
    sidebarMini() {
      this.minimizeSidebar();
    },
  },
};
</script>
<style lang="scss">
$scaleSize: 0.95;

@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }

  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
