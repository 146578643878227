<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-text md-card-header-green">
          <div class="card-text">
            <h4 class="title">{{ id == 'new' ? 'INCLUIR' : 'EDITAR' }}</h4>
          </div>
        </md-card-header>

        <md-card-content>
          <md-field>
            <label> Tipo </label>
            <md-select v-model="tipoDicaSaudeId" id="abc" name="abc" @md-selected="onTipoDicaSaudeChange($event)">
              <md-option :value="0">SELECIONAR</md-option>
              <md-option v-for="tipoDicaSaude of tiposDicasSaude" :key="tipoDicaSaude.id" :value="tipoDicaSaude.id">
                {{ tipoDicaSaude.nome | uppercase }}
              </md-option>
            </md-select>
          </md-field>
          <md-field>
            <label> Título </label>
            <md-input v-model="dicaSaude.titulo" type="text" aria-autocomplete="none" auto-complete="off">
            </md-input>
          </md-field>
          <md-field>
            <label> Texto </label>
            <md-textarea v-model="dicaSaude.texto" class="md-autogrow" rows="20"></md-textarea>
          </md-field>

        </md-card-content>
        <md-card-actions md-alignment="right">
          <md-button class="md-default" @click="back()">CANCELAR</md-button>
          <md-button class="md-success" @click="save()">SALVAR</md-button>
        </md-card-actions>
      </md-card>
    </div>

  </div>

</template>
<script>
import api from './../../services/api';

export default {
  components: {},
  data() {
    return {
      id: null,
      tipoDicaSaudeId: 0,
      dicaSaude: {
        texto: '',
        titulo: '',
        tipoDicasSaude: null
      },
      tiposDicasSaude: []
    };
  },
  created() {
    this.getTiposDicasSaude();
    this.id = this.$route.params.id;
    if (this.id != 'new') {
      this.getDicaSaude();
    }
  },
  methods: {
    onTipoDicaSaudeChange(event) {
      this.dicaSaude.tipoDicasSaude = this.tiposDicasSaude.find(t => t.id == event);
    },
    getTiposDicasSaude() {
      api.get(`/tipo-dicas-saude`)
        .then(value => {
          const { data } = value;
          const items = [];
          for (const item of data) {
            const { id, nome } = item;
            items.push({
              id,
              nome
            });
          }
          this.tiposDicasSaude = items;
        })
        .catch(error => console.log(error));
    },
    async save() {
      const { titulo, texto, tipoDicasSaude } = this.dicaSaude;
      if (!titulo) {
        this.notify('Título não informado');
        return;
      }

      if (!texto) {
        this.notify('Texto não informado');
        return;
      }

      if (!tipoDicasSaude) {
        this.notify('Tipo de Dica não informado');
        return;
      }

      try {
        this.id == 'new' ?
          await api.post('/dicas', { ...this.dicaSaude }) :
          await api.patch(`/dicas/${this.id}`, { ...this.dicaSaude });

        this.back();
      } catch (e) {
        const { data } = e;
        const { message } = data;
        this.notify(message ?? data.toString());
      }
    },
    back() {
      this.$router.push({ path: '/admin/dicas-saude', replace: true })
    },
    getDicaSaude() {
      api.get(`/dicas/${this.id}`)
        .then(value => {
          const { data } = value;
          this.dicaSaude = data;
          this.tipoDicaSaudeId = this.dicaSaude.tipoDicasSaude.id ?? 0;
        })
        .catch(error => console.log(error));
    },
    notify(message) {
      this.$notify({
        timeout: 2500,
        message,
        icon: "add_alert",
        horizontalAlign: 'right',
        verticalAlign: 'top',
        type: 'warning',
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.md-inline-checkboxes {
  display: inline-flex;

  .md-checkbox {
    margin-top: 15px !important;
  }
}

.md-checkbox,
.md-radio {
  margin-top: 15px;
  margin-bottom: 0.5rem;
}

.md-checkbox,
.md-radio {
  display: flex;
}

.md-radio .md-radio-container {
  margin-left: 5px;
  position: relative;
  left: -3px;
}

.md-form-label+.md-layout-item .md-checkbox:not(:first-child),
.md-form-label+.md-layout-item+.md-layout-item .md-radio:not(:first-child),
.md-form-label+.md-layout-item .md-radio {
  margin-top: 0;
}

.form-control-static {
  margin-top: 6px;
}
</style>
