<template>
    <md-card :class="['md-card', [8, 12].includes(tipo) ? 'is-medicamentos' : '']">
        <md-card-content class="md-layout">
            <div :class="['md-layout-item', `md-size-${visualizarTotalPeriodo ? 75 : 100}`, 'section-grafico']">
                <h4 class="title" style="margin-bottom: 20px;">
                    {{ titulo }}
                </h4>
                <div :id="chartId"
                    :class="['ct-chart', isNegativo ? 'is-negativo' : 'is-positivo', [8, 12].includes(tipo) ? 'is-medicamentos' : '']">
                </div>
            </div>
            <div class="md-layout-item md-size-25 section-stats" v-if="visualizarTotalPeriodo">
                <span class="title text-small">
                    Total do período
                </span>
                <span class="numbers">
                    <animated-number :value="quantidade"></animated-number>
                </span>
                <knob :value="percentual" :color="isNegativo ? '#e82227' : '#467fc1'" />
            </div>
        </md-card-content>
    </md-card>
</template>
<script>

import AnimatedNumber from './../AnimatedNumber.vue';

import ctPointLabels from "../../utils/ctPointLabels.js"

import { getDashboardUrl, TIPOS_DASHBOARD, getDashboardPeriodo } from './../../utils/dashboards'

import {
    Knob,
} from "@/components";
import api from '../../services/api';



export default {
    components: {
        AnimatedNumber,
        Knob,
    },
    props: {
        periodo: {
            type: Number,
            default: 0,
        },
        mes: {
            type: Object,
            default: null,
        },
        medicamento: {
            type: Number,
            default: 0,
        },
        total: {
            type: Number,
            default: 0,
        },
        tipo: {
            type: Number,
            default: TIPOS_DASHBOARD.usuarios_cadastrados,
        },
        negativo: {
            type: Boolean,
            default: false,
        },
        titulo: {
            type: String,
            default: '',
        },
        visualizarTotalPeriodo: {
            type: Boolean,
            default: true,
        },
        max: {
            type: Number,
            default: -1
        }
    },
    data() {
        return {
            chartId: "no-id",
            chartType: 'Bar',
            quantidade: 0,
            percentual: 0,
            chartData: {
                labels: [],
                series: [],
            },
            chartOptions: {
                seriesBarDistance: 8,
                stackBars: false,
                with: '100%',
                height: '200px',
                showLabel: true,
                labelPosition: 'inside',
                labelDirection: 'implode',
                fullWidth: true,
                classNames: {
                    chartPie: 'ct-chart-pie',
                    chartDonut: 'ct-chart-donut',
                    series: 'ct-series',
                    slicePie: 'ct-slice-pie',
                    sliceDonut: 'ct-slice-donut',
                    sliceDonutSolid: 'ct-slice-donut-solid',
                    label: 'ct-label-over'
                },
                axisX: {
                    showGrid: false,
                    onlyInteger: false,
                },
                axisY: {
                    showGrid: true,
                    onlyInteger: true,
                    labelInterpolationFnc: (text) => text,
                }

            },
            responsiveOptions: [
                [
                    "screen and (max-width: 640px)",
                    {
                        seriesBarDistance: 1,
                        axisX: {
                            labelInterpolationFnc: function (value) {
                                return value[0];
                            },
                        },
                    },
                ],
            ],
        }
    },
    computed: {
        isNegativo() {
            return this.negativo === true;
        }
    },
    methods: {
        initChart(Chartist) {
            var chartIdQuery = `#${this.chartId}`;

            if (this.max != -1) {
                this.chartOptions.high = this.max;
            }
            this.chartOptions["plugins"] = [
                ctPointLabels({
                    textAnchor: 'center',
                    align: "centertop",
                     labelInterpolationFnc: function (value) { return value % 1===0?value: value.toFixed(0) }
                })
            ]

            Chartist[this.chartType](
                chartIdQuery,
                this.chartData,
                this.chartOptions,
                this.chartAnimation
            );
        },
        /***
         * Assigns a random id to the chart
         */
        updateChartId() {
            var currentTime = new Date().getTime().toString();
            var randomInt = this.getRandomInt(0, currentTime);
            this.chartId = `div_${randomInt}`;
        },
        getRandomInt(min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },
        fetchData() {
            const { medicamento, tipo, periodo, mes } = this;

            const url = getDashboardUrl(tipo);

            const [inicio, fim] = getDashboardPeriodo(periodo, mes);

            api.get(url, {
                params: {
                    inicio,
                    fim,
                    medicamento
                }
            }).then(response => {
                const { chartData, quantidade = 0 } = response.data;

                this.chartData = chartData;

                this.quantidade = Number(quantidade);

                import("chartist").then((Chartist) => {
                    let ChartistLib = Chartist.default || Chartist;
                    this.$nextTick(() => {
                        this.initChart(ChartistLib);
                    });
                });

            }).catch(e => console.log());

        },
        setPercentual() {
            const { total, quantidade } = this;

            this.percentual = Math.trunc((quantidade / total) * 100);
        }
    },
    mounted() {
        this.updateChartId();

        this.fetchData();
    },
    watch: {
        periodo: function (newValue) {
            this.fetchData();
        },
        mes: function (newValue) {
            this.fetchData();
        },
        medicamento: function (newValue) {
            this.fetchData();
        },

        total: function (newValue) {
            this.setPercentual();
        },
        quantidade: function (newValue) {
            this.setPercentual();
        }
    }
}
</script>
<style lang="scss" scoped>
.is-medicamentos {
    height: 200px !important;
}

.md-card {
    margin: 10px 0;

    &.is-medicamentos {
        height: 340px !important;
    }

    .md-card-content {
        padding: 10px;
        font-size: 12px;
    }
}

.ct-label-over {
    width: 50px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.section-stats {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    .title {
        font-size: 0.8em;
        font-weight: 600;
    }

    .numbers {
        padding: 0.5em;
        font-size: 2em;
        font-weight: bold;
    }
}
</style>