<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>inbox</md-icon>
          </div>
          <h4 class="title pull-right">
            <router-link to="/admin/dica-saude/new">
              <md-button class="md-success md-sm ">
                Incluir
                <span class="md-label">
                  <md-icon class="material-icons">plus_one</md-icon>
                </span>
              </md-button>
            </router-link>
          </h4>
        </md-card-header>
        <md-card-content>
          <md-table v-model="tableData" table-header-color="green">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="ID">{{ item.id }}</md-table-cell>
              <md-table-cell md-label="Título">{{ item.titulo }}</md-table-cell>
              <md-table-cell md-label="Tipo">{{ item.tipoDicasSaude ? item.tipoDicasSaude.nome : '***' }}</md-table-cell>
              <md-table-cell md-label="Texto">{{ item.texto }}</md-table-cell>
              <md-table-cell md-label="Ações">
                <router-link :to="'/admin/dica-saude/' + item.id">
                  <md-button class="md-just-icon md-info">
                    <md-icon>edit</md-icon>
                  </md-button>
                </router-link>
                &nbsp;
                <md-button class="md-just-icon md-danger" @click="deleteItem(item)">
                  <md-icon>close</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>
<script>

import api from './../../services/api';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      tableData: [],
    };

  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      api.get('/dicas')
        .then(value => {
          const { data } = value;
          this.tableData = data;
        })
        .catch(error => console.log(error));
    },
    deleteItem(item) {
      Swal.fire({
        title: "Deseja mesmo excluir?",
        text: `${item.titulo}`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "md-button md-info",
        cancelButtonClass: "md-button md-danger",
        confirmButtonText: "SIM",
        cancelButtonText: "NÂO",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          api.delete(`/dicas/${item.id}`)
            .then(value => this.getData())
            .catch(error => console.log(error));
        }
      });
    }
  },
};
</script>
<style scoped>
.table-transparent {
  background-color: transparent !important;
}

.mt-0 {
  margin-top: 0 !important;
}
</style>
