<template>
  <input readonly type="text" :value="value" id="tste">
</template>

<script>
import * as $ from 'jquery';

export default {
  props: {
    color: {
      type: String,
      required: false,
      default: '#000'
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      required: false,
      default: 100
    },
    width: {
      type: Number,
      default: 60
    },
    height: {
      type: Number,
      default: 60
    },
    value: {
      type: Number,
      default: 0
    },
  },
  mounted() {
    $(this.$el).knob({
      min: this.min,
      max: this.max,
      width: this.width,
      height: this.height,
      fgColor: this.color
    }).trigger('change')
      .on('change', () => {
        this.$emit('input', this.value)
      });
  },
  watch: {
    value: function (value) {      
      $(this.$el)
        .val(value)
        .trigger('change');
    },
  },
}
</script>
