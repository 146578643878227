<template>
  <footer class="footer">
    <div class="container">
    
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style></style>
