import Vue from "vue";
import VueRouter from "vue-router";
import DashboardPlugin from "./material-dashboard";

// Plugins
import App from "./App.vue";
import Chartist from "chartist";
import api from './services/api'

// router setup
import routes from "./routes/routes";

import "../node_modules/jquery-knob/dist/jquery.knob.min"

// plugin setup
Vue.use(VueRouter);
Vue.use(DashboardPlugin);
// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
  linkExactActiveClass: "nav-item active",
});

// global library setup
Vue.prototype.$Chartist = Chartist;
Vue.prototype.$http = api;

Vue.config.errorHandler = (err, vm, info) => {
  if (process.env.NODE_ENV !== 'production') {
    // Show any error but this one
    if (err.message !== "Cannot read properties of undefined (reading 'badInput')") {
      console.error(err);
    }
  }
};

Vue.filter('uppercase', (value) => {
  if (!value) return ''
  return value.toString().toUpperCase();
})


api.defaults.timeout = 10000;

api.interceptors.request.use(
  config => {
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers.common["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  response => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  error => {
    if (error.response.status) {
      switch (error.response.status) {
        case 400:
          //do something
          break;
        case 401:
        case 403:
          router.replace({
            path: "/login",
          });
          break;
        case 404:
          alert('page not exist');
          break;
        case 502:
          setTimeout(() => {
            router.replace({
              path: "/login",
            });
          }, 1000);          
      }
      return Promise.reject(error.response);
    }
    return Promise.reject(error);
  }
);

/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: (h) => h(App),
  router,
});
