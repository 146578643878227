<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title pull-right">
            <router-link to="/admin/medicamento/new">
              <md-button class="md-success md-sm ">
                Incluir
                <span class="md-label">
                  <md-icon class="material-icons">plus_one</md-icon>
                </span>
              </md-button>
            </router-link>
          </h4>
        </md-card-header>
        <md-card-content>
          <md-table v-model="tableData" table-header-color="green">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="ID">{{ item.id }}</md-table-cell>
              <md-table-cell md-label="Nome">{{ item.nome }}</md-table-cell>
              <md-table-cell md-label="Concentração">{{ item.concentracao }}</md-table-cell>
              <md-table-cell md-label="Tipo">
                {{ item.tipoMedicamento ? item.tipoMedicamento.nome : 'BRIGATINIBE' }}
              </md-table-cell>
              <md-table-cell md-label="Controla Estoque">
                {{ item.controlaEstoque ? 'SIM' : 'NÂO' }}
              </md-table-cell>
              <md-table-cell md-label="Vacina">
                {{ item.vacina ? 'SIM' : 'NÂO' }}
              </md-table-cell>
              <md-table-cell md-label="Status">
                {{ item.ativo ? 'ATIVO' : 'INATIVO' }}
              </md-table-cell>
              <md-table-cell md-label="Pacientes">
                {{ item.quantidade }}
              </md-table-cell>
              <md-table-cell md-label="%" class="text-center">
                <div class="text-center h6">
                  {{ item.percentual }}%
                </div>
              </md-table-cell>
              <md-table-cell md-label="Ações">
                <router-link :to="'/admin/medicamento/' + item.id">
                  <md-button class="md-just-icon md-info">
                    <md-icon>edit</md-icon>
                  </md-button>
                </router-link>
                <md-button :class="'md-just-icon ' + (item.ativo ? 'md-primary' : 'md-default')" @click="setStatus(item)">
                  <md-icon>{{ item.ativo ? 'toggle_off' : 'toggle_on' }}</md-icon>
                </md-button>
                <md-button class="md-just-icon md-danger" @click="deleteItem(item)">
                  <md-icon>close</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>
<script>

import api from './../../services/api';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      tableData: [],
    };
  },
  mounted() {
    this.getMedicamentos();
  },
  methods: {
    getMedicamentos() {
      api.get('/medicamentos/admin')
        .then(value => {
          const { data } = value;
          this.tableData = data;
        })
        .catch(error => console.log(error));
    },
    setStatus(item) {
      Swal.fire({
        title: `Deseja mesmo ${item.ativo ? 'Inativar' : 'Reativar'}?`,
        text: `${item.nome}`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "md-button md-info",
        cancelButtonClass: "md-button md-danger",
        confirmButtonText: "SIM",
        cancelButtonText: "NÂO",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          api.put(`/medicamentos/${item.id}/ativo`, {
            ativo: !item.ativo
          })
            .then(value => this.getMedicamentos())
            .catch(error => console.log(error));
        }
      });
    },
    deleteItem(item) {
      if (item.quantidade > 0) {
        this.notify('Não é possível excluir um medicamento em uso por algum paciente, você deve inativá-lo!');
        return;
      }
      Swal.fire({
        title: "Deseja mesmo excluir?",
        text: `${item.nome}`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "md-button md-info",
        cancelButtonClass: "md-button md-danger",
        confirmButtonText: "SIM",
        cancelButtonText: "NÂO",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          api.delete(`/medicamentos/${item.id}`)
            .then(value => this.getMedicamentos())
            .catch(error => console.log(error));
        }
      });
    },
    notify(message) {
      this.$notify({
        timeout: 2500,
        message,
        icon: "add_alert",
        horizontalAlign: 'right',
        verticalAlign: 'top',
        type: 'warning',
      });
    }
  },
};
</script>
<style scoped>
.table-transparent {
  background-color: transparent !important;
}

.mt-0 {
  margin-top: 0 !important;
}
</style>
