
import axios from 'axios'

export default axios.create({
    baseURL: process.env.NODE_ENV === "production" ?
        'https://www.takecaretratamento.com.br' :
        'http://localhost:3333',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});