<template>
  <div class="md-layout">
    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-50">
      <md-card class="md-card">
        <md-card-content class="md-card-content md-layout">
          <label>
            Selecione um ou mais produtos na lista
          </label>
          <md-field>
            <md-select v-model="medicamento">
              <md-option :value="0">TODOS</md-option>
              <md-option :value="medicamento.id" v-for="medicamento in medicamentos" :key="medicamento.id">
                {{ medicamento.nome }}
              </md-option>
            </md-select>
          </md-field>
        </md-card-content>
      </md-card>
    </div>
    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-50">
      <md-card class="md-card">
        <md-card-content class="md-card-content md-layout">
          <label>
            Selecione um período
          </label>
          <md-field>
            <md-select v-model="periodo">
              <md-option :value="periodo.id" v-for="periodo in periodos" :key="periodo.id">
                {{ periodo.descricao }}
              </md-option>
            </md-select>
          </md-field>
          <month-picker class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100" lang="pt"
            v-if="periodo === 1" @change="changeMonth"></month-picker>
        </md-card-content>
      </md-card>
    </div>
    <div class="md-layout-item md-size-50 md-small-size-100">
      <div class="group-title">
        Usuários
      </div>
      <div style="padding: 8px;">
        <p class="category">
          Total de usuários cadastrados
        </p>
        <span class="title" style="font-size:16pt; font-weight:500">
          <animated-number :value="totalItems.pacientes"></animated-number>
        </span>
        Total de usuários ativos <span style="font-size:x-small;">(todos em uso do app)</span>
      </div>
      <card-dashboard-data :periodo="periodo" :mes="mesSelecionado" :medicamento="medicamento"
        :total="totalItems.pacientes" :tipo="TIPOS_DASHBOARD.usuarios_cadastrados" titulo="Novos usuários cadastrados" />
      <card-dashboard-data :periodo="periodo" :mes="mesSelecionado" :medicamento="medicamento"
        :total="totalItems.excluidos" :negativo="true" titulo="Usuários excluídos"
        :tipo="TIPOS_DASHBOARD.usuarios_excluidos" />
      <card-dashboard-data :periodo="periodo" :mes="mesSelecionado" :medicamento="medicamento" :total="totalItems.admin"
        :tipo="TIPOS_DASHBOARD.usuarios_admin" titulo="Usuários “admin” cadastrados" />
      <card-dashboard-data :periodo="periodo" :mes="mesSelecionado" :medicamento="medicamento"
        :total="totalItems.dependentes" :tipo="TIPOS_DASHBOARD.usuarios_dependentes" titulo="Dependentes cadastrados" />
      <card-dashboard-data :periodo="periodo" :mes="mesSelecionado" :medicamento="medicamento"
        :tipo="TIPOS_DASHBOARD.medicamentos_usuario" titulo="Medicamentos cadastrados por usuário"
        :visualizar-total-periodo="false" />

    </div>
    <div class="md-layout-item md-size-50 md-small-size-100">
      <div class="group-title">
        Cuidadores
      </div>
      <div style="padding: 8px;">
        <p class="category">
          Total de cuidadores cadastrados
        </p>
        <span class="title" style="font-size:16pt; font-weight:500">
          <animated-number :value="totalItems.cuidadores"></animated-number>
        </span>
        Total de cuidadores ativos <span style="font-size:x-small;">(todos em uso do app)</span>
      </div>
      <card-dashboard-data :periodo="periodo" :mes="mesSelecionado" :medicamento="medicamento"
        :total="totalItems.cuidadores" :tipo="TIPOS_DASHBOARD.cuidadores_cadastrados" titulo="Cuidadores cadastrados" />
      <card-dashboard-data :periodo="periodo" :mes="mesSelecionado" :medicamento="medicamento"
        :total="totalItems.cuidadores_alertas" :tipo="TIPOS_DASHBOARD.cuidadores_alertas"
        titulo="Emails de alertas de esquecimento disparados" :negativo="true" />
      <card-dashboard-data :periodo="periodo" :mes="mesSelecionado" :medicamento="medicamento"
        :total="totalItems.cuidadores_aceites" :tipo="TIPOS_DASHBOARD.cuidadores_aceites"
        titulo="Usuários aceitaram a tomada de dose" />
      <card-dashboard-data :periodo="periodo" :mes="mesSelecionado" :medicamento="medicamento"
        :total="totalItems.cuidadores_nao_tomaram" :tipo="TIPOS_DASHBOARD.cuidadores_nao_tomaram"
        titulo="Usuários não tomaram" :negativo="true" />
    </div>

    <div class="md-layout-item md-size-100 md-small-size-100">
      <div class="group-title">
        Dados gerais
      </div>
      <div class="wrapper" style="width: 100%;">
        <card-dashboard-data :periodo="periodo" :medicamento="medicamento" :mes="mesSelecionado"
          :tipo="TIPOS_DASHBOARD.usuarios_genero" titulo="Gênero" :visualizar-total-periodo="false" />
        <card-dashboard-data :periodo="periodo" :mes="mesSelecionado" :medicamento="medicamento"
          :tipo="TIPOS_DASHBOARD.usuarios_idade" titulo="Idade" :visualizar-total-periodo="false" />
        <card-dashboard-data :periodo="periodo" :mes="mesSelecionado" :medicamento="medicamento"
          :tipo="TIPOS_DASHBOARD.usuarios_tempo_uso" titulo="Tempo médio de uso do APP (pós-cadastro)"
          :visualizar-total-periodo="false" :max="100" />
        <card-dashboard-data :periodo="periodo" :mes="mesSelecionado" :medicamento="medicamento"
          :tipo="TIPOS_DASHBOARD.usuarios_inicio_uso" titulo="Data de início da utilização do APP (pós-cadastro)"
          :visualizar-total-periodo="false" :max="100" style="max-height: 74.5%; overflow:hidden; padding-top:15px" />

      </div>

    </div>
  </div>
</template>

<script>

import api from './../../services/api';
import { TIPOS_DASHBOARD } from './../../utils/dashboards'
import { MonthPicker } from 'vue-month-picker'

import {
  AnimatedNumber,
  CardDashboardData
} from "@/components";

export default {
  components: {
    AnimatedNumber,
    CardDashboardData,
    MonthPicker
  },
  props: { user: Object },
  data() {
    return {
      medicamentos: [],
      medicamento: 0,
      periodo: 0,
      TIPOS_DASHBOARD: {
        ...TIPOS_DASHBOARD
      },
      periodos: [
        { id: 0, descricao: 'Fiscal year Takeda (abr-mar)' },
        { id: 1, descricao: 'Mensal' },
        { id: 2, descricao: 'Semanal' },
        { id: 3, descricao: 'YTD (a partir de abr)' },
        { id: 4, descricao: 'MAT (ano calendário)' },
      ],
      mesSelecionado: {},
      meses: [
        { id: 1, descricao: 'Janeiro' },
        { id: 2, descricao: 'Fevereiro' },
        { id: 3, descricao: 'Maço' },
        { id: 4, descricao: 'Abril' },
        { id: 5, descricao: 'Maio' },
        { id: 6, descricao: 'Junho' },
        { id: 7, descricao: 'Julho' },
        { id: 8, descricao: 'Agosto' },
        { id: 9, descricao: 'Setembro' },
        { id: 10, descricao: 'Outubro' },
        { id: 11, descricao: 'Novembro' },
        { id: 12, descricao: 'Dezembro' }

      ],
      totalItems: {
        cuidadores: 0,
        pacientes: 0,
        excluidos: 0,
        admin: 0,
        dependentes: 0,
      },
      usuarios: {
        excluidos: {
          titulo: '',
          positivo: false,
          subtitulo: '(soma de todos perfis e dependentes excluidos)',
          percentual: 12,
          quantidade: 254,
          meses: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        },
        admin: {
          titulo: 'Usuários “admin” cadastrados',
          percentual: 51,
          quantidade: 1070,
          meses: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        },
        dependentes: {
          titulo: '',
          percentual: 51,
          quantidade: 1070,
          meses: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        },
      },
      cuidadores: {
        cadastrados: {
          titulo: 'Total de cuidadores cadastrados',
          percentual: 54,
          quantidade: 274,
          meses: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        },
        emailsAlertas: {
          titulo: 'Emails de alertas disparados para cuidadores',
          positivo: false,
          percentual: 24,
          quantidade: 71,
          meses: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        },
        usuariosAceite: {
          titulo: 'Usuários aceitaram a tomada de dose',
          percentual: 51,
          quantidade: 1070,
          meses: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        },
        usuariosNaoTomaram: {
          titulo: 'Usuários não tomaram',
          subtitulo: '(ignoraram ou adiaram a dose)',
          positivo: false,
          percentual: 49,
          quantidade: 1046,
          meses: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        }
      },
      pieChart: {
        data: {
          series: [50],
        },
        options: {
          total: 100,
          donutWidth: 20,
          donut: true,
          height: "230px",
        },
      },


    };
  },
  mounted() {
    this.getData();
    this.getMedicamentos();
  },
  methods: {
    getData() {
      api.get('/dashboards')
        .then(value => {
          const { data } = value;
          this.totalItems = data;
        })
        .catch(error => console.log(error));
    },
    getMedicamentos() {
      api.get('/medicamentos/admin')
        .then(value => {
          const { data } = value;
          this.medicamentos = data;
        })
        .catch(error => console.log(error));
    },
    changeMonth(date) {
      this.mesSelecionado = date
    }
  }
};
</script>
<style lang="scss" scoped>
.md-card {
  margin: 10px 0 !important;
}

.group-title {
  font-weight: bold;
  border-bottom: 2px solid black;
  margin: 8px;
}


@media (min-width: 600px) {
  .wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
}
</style>